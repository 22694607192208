/** @jsx jsx */
import { jsx, useTheme, css } from "@emotion/react";
import modularityAreaContext from "@municipio/gatsby-theme-basic/src/modularityAreaContext.js";
import modularityModuleContext from "@municipio/gatsby-theme-basic/src/modularityModuleContext";
import modularityRowContext from "@municipio/gatsby-theme-basic/src/modularityRowContext";
import ModuleController from "@municipio/gatsby-theme-basic/src/wsui/components/ModuleController.jsx";
import {
  MaybeFragment,
  PageSection as DefaultPageSection,
  PageGrid,
  PageGridItem,
  useThemeProps,
  handleComponentsProp,
  Section,
} from "@wsui/base";
// import clsx from "clsx";
import { omit } from "lodash/fp";

function defaultShouldMakePageSection({ moduleRow }) {
  if (moduleRow.modules.length > 1) {
    return true;
  }
  let module = moduleRow.modules[0];
  if (module.colspan !== 12) {
    return true;
  }
}

export default function ModularityArea(props) {
  // eslint-disable-next-line no-unused-vars
  const theme = useTheme();
  props = useThemeProps({ props, name: "ModularityArea" });
  let {
    moduleRows,
    defaultColspan = 7,
    context = {},
    gap = [8.75, 17.5],
    headingVariant,
    maxColspan,
    pageGridProps = {},
    sectionPadding = gap,
    components,
    promoteFirstHeading = false,
    shouldMakePageSection = defaultShouldMakePageSection,
    ...restProps
  } = omit(["marginAfter", "marginBefore"], props);

  let { PageSection } = handleComponentsProp(components, {
    PageSection: DefaultPageSection,
  });

  if (!moduleRows?.length) {
    return null;
  }

  return (
    <MaybeFragment {...restProps}>
      <modularityAreaContext.Provider value={{ ...context }}>
        {moduleRows.map((moduleRow, rowIndex) => {
          const bigBackgroundImageModule = moduleRow.modules.find((module) =>
            ["brochures", "image"].includes(
              module.module?.modBillboard?.format,
            ),
          );

          const backgroundImage =
            bigBackgroundImageModule?.module?.modBillboard.image?.src;
          let { modules, background } = moduleRow;

          background = backgroundImage ? "blue" : background;

          return shouldMakePageSection(
            { moduleRow },
            defaultShouldMakePageSection,
          ) ?? true ? (
            <modularityRowContext.Provider
              key={rowIndex}
              value={{ modules, index: rowIndex }}
            >
              <PageSection
                css={css`
                  ${backgroundImage &&
                  `
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-position: center;
                  background-image: url(${backgroundImage})`}
                `}
                background={background}
                spacing={sectionPadding}
              >
                <PageGrid
                  key={rowIndex}
                  as="div"
                  rowGap={gap}
                  maxColspan={maxColspan}
                  {...pageGridProps}
                >
                  {modules.map(
                    ({ hidden, module, colspan, ...rest }, index) => {
                      return (
                        <PageGridItem
                          key={index}
                          colspan={colspan || defaultColspan}
                        >
                          <Section
                            adjustLevel={
                              promoteFirstHeading &&
                              rowIndex === 0 &&
                              index === 0
                                ? -1
                                : 0
                            }
                          >
                            <modularityModuleContext.Provider
                              value={{
                                hidden,
                                module,
                                colspan,
                                headingVariant,
                                ...rest,
                              }}
                            >
                              <ModuleController module={module} />
                            </modularityModuleContext.Provider>
                          </Section>
                        </PageGridItem>
                      );
                    },
                  )}
                </PageGrid>
              </PageSection>
            </modularityRowContext.Provider>
          ) : (
            <div>
              {modules.map(({ hidden, module, colspan, ...rest }, index) => {
                return (
                  <Section
                    key={index}
                    adjustLevel={
                      promoteFirstHeading && rowIndex === 0 && index === 0
                        ? -1
                        : 0
                    }
                  >
                    <modularityModuleContext.Provider
                      value={{
                        hidden,
                        module,
                        colspan,
                        headingVariant,
                        ...rest,
                      }}
                    >
                      <ModuleController module={module} />
                    </modularityModuleContext.Provider>
                  </Section>
                );
              })}
            </div>
          );
        })}
      </modularityAreaContext.Provider>
    </MaybeFragment>
  );
}
