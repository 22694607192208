/** @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import PageBottomSidebarModules from "@municipio/gatsby-theme-basic/src/wsui/components/PageBottomSidebarModules.jsx";
import PageContentAreaBottomModules from "@municipio/gatsby-theme-basic/src/wsui/components/PageContentAreaBottomModules.jsx";
import PageContentAreaModules from "@municipio/gatsby-theme-basic/src/wsui/components/PageContentAreaModules.jsx";
import PageTopSidebarModules from "@municipio/gatsby-theme-basic/src/wsui/components/PageTopSidebarModules.jsx";
import usePageModules from "@municipio/gatsby-theme-basic/src/wsui/usePageModules.js";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/page-context";
import {
  PageContent,
  PageHeading,
  PagePreamble,
  PageBreadcrumbs,
  PageFeaturedImage,
  PageFooter as DefaultPageFooter,
  PageBottom as DefaultPageBottom,
  Seo,
} from "@whitespace/gatsby-theme-wordpress-basic/src/wsui/components";
import {
  Section,
  PageGrid,
  PageGridItem,
  useThemeProps,
  PageSection,
  handleComponentsProp,
  OutlineNav,
} from "@wsui/base";
import { omit } from "lodash/fp";
import { Fragment, useState } from "react";

export default function DefaultTemplate(props) {
  const theme = useTheme();
  const pageContext = usePageContext();
  const { title, content, isFrontPage, pageAppearance } = pageContext;
  props = useThemeProps({
    props,
    name: "DefaultTemplate",
    context: { pageContext },
  });
  props = useThemeProps({ props, name: "Template", context: { pageContext } });
  let {
    defaultColspan = 7,
    hideTitle = null,
    //contentSpacing = [5, 10],
    components,
    showOutlineNav = false,
    footerMargin = 0,
  } = omit(["spacing"], props);
  components = handleComponentsProp(components, {
    PageBottom: DefaultPageBottom,
    PageFooter: DefaultPageFooter,
  });
  let {
    PageBottom,
    // PageFooter,
  } = components;
  showOutlineNav = pageContext.pageAppearance?.showOutlineNav ?? showOutlineNav;
  const [mainElement, setMainElement] = useState(null);

  let topSidebarModules = usePageModules("topSidebar");
  let bottomSidebarModules = usePageModules("bottomSidebar");
  let hasTopSidebarModules = topSidebarModules?.length > 0;
  let hasBottomSidebarModules = bottomSidebarModules?.length > 0;
  let hasContentAreaModules =
    usePageModules("contentArea", { ignoreBackgrounds: true })?.length > 0;
  let hasContentAreaBottomModules =
    usePageModules("contentAreaBottom", { ignoreBackgrounds: true })?.length >
    0;
  let hasMainContent = !!content || !!hasContentAreaModules;

  hideTitle ??= pageAppearance?.hideTitle ?? hasTopSidebarModules;

  const ownerState = {
    hasSidebar: false,
    hasTopSidebarModules,
    hasContentAreaModules,
    hasContentAreaBottomModules,
    hasBottomSidebarModules,
    hasMainContent,
    hideTitle,
    defaultColspan,
  };

  return (
    <article
      ref={(el) => setMainElement(el)}
      css={css`
        padding-bottom: ${!hasBottomSidebarModules && footerMargin
          ? theme.getLength(footerMargin)
          : null};
      `}
    >
      <Seo title={title} isFrontPage={isFrontPage} />
      {isFrontPage && (
        <h1
          css={css`
            ${theme.styleUtils.visuallyHidden}
          `}
        >
          Startsida
        </h1>
      )}
      {showOutlineNav && (
        <PageSection
          background="transparent"
          css={css`
            position: sticky;
            top: 0.5rem;
            z-index: 1;
          `}
        >
          <PageGrid>
            <PageGridItem colspan={defaultColspan}>
              <Section>
                <OutlineNav
                  source={mainElement}
                  css={css`
                    margin-block-end: 2rem;
                  `}
                />
              </Section>
            </PageGridItem>
          </PageGrid>
        </PageSection>
      )}
      <Section>
        <PageTopSidebarModules promoteFirstHeading={hideTitle} />
      </Section>
      <PageBreadcrumbs />
      {!!(
        content ||
        !hideTitle ||
        hasContentAreaModules ||
        hasContentAreaBottomModules
      ) && (
        <Fragment>
          {!!(content || !hideTitle) && (
            <PageSection background="transparent">
              <PageGrid>
                <PageGridItem colspan={defaultColspan}>
                  <PageHeading marginAfter hideTitle={hideTitle} />
                </PageGridItem>
              </PageGrid>
              <PageGrid>
                <PageGridItem colspan={12}>
                  <PageFeaturedImage />
                </PageGridItem>
              </PageGrid>
              <PageGrid>
                <PageGridItem colspan={defaultColspan}>
                  <Section>
                    <PagePreamble marginAfter marginBefore />
                    <PageContent />
                  </Section>
                </PageGridItem>
              </PageGrid>
            </PageSection>
          )}
          <Section>
            <PageContentAreaModules maxColspan={defaultColspan} />
            <PageContentAreaBottomModules />
          </Section>
        </Fragment>
      )}
      <Section>
        <PageBottomSidebarModules />
        <PageBottom ownerState={ownerState} />
      </Section>
    </article>
  );
}
